import React from 'react';
import { DetailedCardProps, DetailedCard, TagProps, TooltipProps } from '@clarke-energia/foton';
import CCEELogo from '../../../assets/ccee-logo.svg';
import numeral from '@utils/numeral';
import { ParsedBidsData } from '@pages/commercial-group/savings-result';
import TableDisplay from './table-display';
import { CLARKE_LOGO } from '@utils/constants/common';

interface ScopeDisplayProps {
  bids: ParsedBidsData[];
  type: 'INSIDE' | 'OUTSIDE';
}

type hightlightStyletype = {
  label: string;
  color: 'green' | 'primary' | 'yellow' | undefined;
  tooltip: TooltipProps;
};

const checkhighLightCardInfo = (bestScoreIdx: number, currentItem: number): hightlightStyletype => {
  const hightlightStyle: hightlightStyletype = { label: '', color: undefined, tooltip: { content: '' } };
  const tooltipContentAvaliationDescription =
    'Fazemos uma avaliação de cada fornecedora com base em fatores qualitativos como, nível de atendimento, flexibilidade nas negociações e aderência ao produto solicitado. Também fatores quantitativos como, volume comercializado, patrimônio líquido, liquidez corrente e índice de endividamento. A partir desses requisitos atribui-se uma nota de 0 a 5 pontos.';

  if (bestScoreIdx === -1) {
    hightlightStyle.color = undefined;
  }

  if (bestScoreIdx === 0 && currentItem === 0) {
    hightlightStyle.label = 'Maior economia e melhor avaliação ';
    hightlightStyle.color = 'green';
    hightlightStyle.tooltip.content = tooltipContentAvaliationDescription;
  }
  if (bestScoreIdx !== 0 && currentItem === 0) {
    hightlightStyle.label = 'Maior economia ';
    hightlightStyle.color = 'primary';
  }
  if (bestScoreIdx > 0 && bestScoreIdx === currentItem) {
    hightlightStyle.label = 'Melhor avaliação ';
    hightlightStyle.color = 'yellow';
    hightlightStyle.tooltip.content = tooltipContentAvaliationDescription;
  }

  return hightlightStyle;
};

const cceeTooltipContent =
  'As empresas no mercado livre de energia participam do pagamento do Encargo de Serviços do Sistema para a Câmara de Comercialização de Energia Elétrica (CCEE). Caso haja cobertura, a fornecedora que fica responsável pelo pagamento.';

const highLightCard = (cards: ParsedBidsData[], isInsideScope: boolean, bestScoreidx: number): DetailedCardProps[] => {
  return cards.map((card, idx) => {
    const tagContent: TagProps = {
      color: 'gray',
      kind: 'icon',
      icon: 'CalendarIcon',
      label: `Validade: ${card.deadline}`,
    };
    const tagHighlight: TagProps = {
      color: isInsideScope ? 'green' : 'white',
      kind: 'default',
      label: `${card.totalPercentageAmount}%`,
    };

    const tagOutsideScope: TagProps = {
      color: 'red',
      kind: 'default',
      label: 'Fora do escopo',
    };
    const rank = `${idx + 1}º`;

    const highlightInfo = checkhighLightCardInfo(bestScoreidx, idx);
    const hightlightStyle = highlightInfo?.color ? { hightlightStyle: highlightInfo } : undefined;

    return {
      ...(isInsideScope && hightlightStyle),
      tag: tagContent,
      imageContent: {
        imgPath: CLARKE_LOGO,
        ...(isInsideScope ? { text: rank } : { tag: tagOutsideScope }),
        ...(card.traderScore && {
          textWithIcon: { iconName: 'StarIcon', text: numeral(card.traderScore).format('0,0.00') },
        }),
      },
      cardHighLightInfo: {
        title: 'Economia',
        tag: tagHighlight,
        value: card.totalCurrencyAmount,
      },
      cardContent: [
        { title: 'VPL de economia', iconName: 'FaceSmileIcon', value: card.npv },
        { title: 'Flexibilidade', iconName: 'AdjustmentsVerticalIcon', value: card.flexibility },
        { title: 'Garantia', iconName: 'BriefcaseIcon', value: card.guaranteeType },
        { title: 'Modalidade de contratação', iconName: 'NewspaperIcon', value: card.traderType },
        {
          title: 'Encargos CCEE',
          imgPath: CCEELogo,
          value: card.cceeCoverTaxes,
          tooltip: { content: cceeTooltipContent },
        },
      ],
    };
  });
};

const EmptyScope: React.FC<{ isInsideScope: boolean }> = ({ isInsideScope }) => {
  const scopeTxt = isInsideScope ? 'dentro do escopo' : 'fora do escopo';
  return (
    <div className="flex flex-col col-span-8 gap-5 h-full">
      <div className="text-heading-large">{`Sem propostas ${scopeTxt} para esse processo.`} </div>
      <div className="text-paragraph-medium">{`Não há nenhuma proposta ${scopeTxt} para esse processo.`}</div>
    </div>
  );
};

const ScopeDisplay: React.FC<ScopeDisplayProps> = ({ bids, type }) => {
  const highlights: ParsedBidsData[] = bids.length >= 3 ? bids.slice(0, 3) : bids;

  const hasValidScore = highlights.some((highlight) => highlight.traderScore);

  const maxScoreValue = hasValidScore && Math.max(...highlights.map((highlight) => highlight.traderScore));

  const bestScoreIdx = highlights.findIndex((item) => item.traderScore === maxScoreValue);

  const isInsideScope = type === 'INSIDE';
  const formatedHighlights = highLightCard(highlights, isInsideScope, bestScoreIdx);

  if (!bids.length) return <EmptyScope isInsideScope={isInsideScope} />;

  return (
    <div className="grid grid-cols-1 gap-6 lg:grid-cols-9 -z-10">
      {formatedHighlights.map(({ cardContent, cardHighLightInfo, imageContent, tag, hightlightStyle }, idx) => (
        <DetailedCard
          key={`card-${idx}`}
          tag={tag}
          hightlightStyle={hightlightStyle}
          cardHighLightInfo={cardHighLightInfo}
          imageContent={imageContent}
          cardContent={cardContent}
          className={`${isInsideScope && idx !== 0 ? 'self-end' : ''} col-span-full lg:col-span-3`}
        />
      ))}
      <div className="col-span-full mt-8">
        <h2 className="mb-6 text-heading-medium">Todas as propostas detalhadas</h2>
        <TableDisplay data={bids} />
      </div>
    </div>
  );
};
export default ScopeDisplay;
